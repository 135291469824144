import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import SeoImage from '../assets/images/promo-weight-loss.jpg'

class WeightLossMontclair extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query WeightLossMontclairMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout>
                    <Seo title={`Medically Supervised Weight Loss – Montclair, NJ | ${data.site.siteMetadata.title}`} description={`Medically supervised weight loss in Caldwell helps to guide patients towards healthier weight by combining medication, nutrition counseling, and exercise programs.`} image={SeoImage} keywords={[``]} />
                    <Banner title='Medically Supervised Weight Loss – Montclair, NJ' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={SeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <p class='introduction'>Every weight loss program discusses the formula of calories in versus calories out. While this is an accurate depiction of fat loss, other factors such as medical issues and metabolism can make weight loss extremely complicated for certain people. Patients that have high levels of body fat are statistically at a much higher rate of various health issues and diseases such as heart disease, hypertension, strokes, and diabetes.</p>

                                    <p>Simply eating healthy and going to the gym may not produce the results that you want. Medically supervised weight loss in Montclair helps patients achieve health and weight goals by using the combination of nutrition counseling, exercise programs, and medication. These programs have helped countless individuals over the years, and you could be the next success story!</p>

                                    <h2>How does it work?</h2>

                                    <p>Medically supervised weight loss in Montclair caters the weight loss journey to each individual patient. There is no single nutrition counseling or weight loss program that works across the board, because every patient has unique issues, goals, strengths, and weaknesses.</p>

                                    <p>A typical medically supervised weight loss program with Optimal Health and Wellness generally has 4 pillars that lead to positive results.</p>

                                    <p>The first step for medically supervised weight loss is <strong>Consultation and Planning</strong>. You’ll start the process by contacting your Montclair doctor to schedule an initial consultation. Here we will discuss all of the important and relevant pre-program information such as medical history, previous weight loss efforts, and psychological history.</p>

                                    <p>We will also see if there are potentially undiagnosed issues such as thyroid issues, sleep problems, liver issues, digestive issues, etc. This could potentially lead to some additional testing in order to corroborate undiagnosed issue theories.</p>

                                    <p>Once this is completed, we will discuss goals, the time it will take to hit those goals, and what type of meal plans and medication may be involved.</p>

                                    <p><strong>Meal Replacements</strong> are an important part of medical weight loss programs. Some programs have a loose meal plan that could just include a general plan with certain foods that should be prioritized, or macronutrient and calorie numbers that need to be hit daily. Other meal replacement programs partner with meal prep companies to provide exact daily meals that sometimes really only need to be heated up at meal times.</p>

                                    <p>The correct meal replacement method will be discussed in the planning stage, and will be a specific solution for each Montclair patient. Insurance coverage may be a factor as well, as many insurance companies are willing to contribute in some way towards weight loss programs.</p>

                                    <p><strong>Weight Loss Medication</strong> is obviously a very important aspect of medical weight loss. Exercise and diet alone often is not enough. So, your doctor at Optimal Health and Wellness will go over different prescription options that can assist you on your weight loss journey.</p>

                                    <p>A vital factor in your medically supervised weight loss journey are <strong>Follow-Up</strong> appointments. Patients will usually schedule check-ins every 3 to 4 weeks, continuing to show up in these intervals until initial weight loss goals are met. For example, this frequency may be performed until a patient loses a certain number of pounds, or 10% of their body weight. At that point, follow-up intervals can be less frequent, as the patient is clearly showing a level of commitment that requires less monitoring.</p>

                                    <h2>Results</h2>

                                    <p>Just like the program itself, results will vary between each individual. This will be due not only to the inherent differences in each patient’s body such as metabolism or medical issues, but also due to commitment levels, response to certain medication, and plenty more.</p>

                                    <p>Some patients see up to 40 or 50 pounds lost in 18-24 weeks. Some may exceed this, and others may not hit those types of numbers. That is why the follow-up appointments are so important. Your doctor will be able to update your plan depending on the results you are seeing between each check-in.</p>

                                    <h2>Call Today</h2>

                                    <p>If weight loss has been a difficult progress for you, or maybe you simply aren’t seeing the results that you want despite constantly dieting and exercising, <a href={`tel:${data.site.siteMetadata.phone}`} rel='noopener noreferrer'>call {data.site.siteMetadata.title}</a> for a consultation with a reputable Montclair doctor. We can go over a plan to help you reach your goals and give you a renewed confidence in your life. Call today!</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasCare={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default WeightLossMontclair